
<script setup lang="ts">
    import { provide } from "vue";

    export interface Props {
        textOnly?: boolean;
    };

    export interface RelatedItemsProvide {
        textOnly: boolean;
    };

    const props = defineProps<Props>();

    provide<RelatedItemsProvide>("RelatedItemsProvider", { textOnly: props.textOnly });
</script>

<template>
    <slot />
</template>
